.reviews-scroller {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping of child elements */
  gap: 20px;
  background: #000;
}

.review {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* This will push the footer to the bottom */
  background-color: #333;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

blockquote {
  quotes: "“" "”" "‘" "’";
  font-size: 16px;
  line-height: 1.4;
  margin: 0 0 10px;
}

.review blockquote:before {
  content: open-quote;
  font-size: 30px;
  line-height: 0.1em;
  margin-right: 10px;
  vertical-align: -0.4em;
}

.review blockquote:after {
  content: close-quote;
  font-size: 30px;
  line-height: 0.1em;
  margin-left: 10px;
  vertical-align: -0.4em;
}

.review footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  text-align: right;
  margin-top: auto; /* Ensures footer sticks to the bottom */
}

.read-more {
  background: none;
  border: none;
  color: #FFF;
  cursor: pointer;
  padding: 5px;
  font-size: 0.8em;
  text-decoration: underline;
  /* Make on left */
  margin-right: auto;

}

.reviewer-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px; /* Space between image and text */
}

@media screen and (max-width: 768px) {
  .reviews-scroller {
    flex-direction: column;
    align-items: center;
    gap: 10px; /* Smaller gap for smaller screens */
  }

  .review {
    margin: 0; /* Remove margin if any */
  }
}
