/* This class will be added to the body when the lightbox is open */

.photo-gallery h2 {
  margin: 20px 0;
  font-size: 1.8rem;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 10px;
  border-bottom: 2px solid #ddd;
  display: inline-block;
  width: auto;
}