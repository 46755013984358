/* src/components/AddPhotoMetaData/AddPhotoMetaData.css */
.container {
    padding: 20px;
}

.img {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.textfield {
    margin-bottom: 20px;
}

.category-heading {
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: bold;
}
