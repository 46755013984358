/* src/components/AuthModal/AuthModal.css */
.modal-box {
  padding: 20px;
  border-radius: 8px;
  background-color: #fff; /* Background color to white for contrast */
  color: #000; /* Default text color set to black */
}

.modal-header {
  margin-bottom: 20px;
}

.modal-header h2 {
  color: #000; /* Ensure the header text is black */
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.modal-footer label {
  display: flex;
  align-items: center;
  color: #000; /* Ensure the footer text is black */
}

.modal-footer label input {
  margin-right: 8px;
}
