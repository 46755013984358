.amenities-container {
  width: 100%;
  color: #fff;
  position: relative;
  background-size: cover;
}

.amenities-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background:   rgba(0, 0, 0, 0.5);
  

}

.amenities-content {
  max-width: 1200px;
  /* margin: 0 auto; */
  /* padding: 40px 20px; */
  position: relative;
  
}

.amenities-header h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #fff;
  /* Ensure header color is white */
}

.amenities-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.amenity-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Align items to the left */
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  /* Transparent white background */
  border: #bdbdbd 1px solid;
}

.amenity-item i,
.amenity-item svg {
  margin-bottom: 10px;
  /* Space between icon and text */
  color: #fff;
  /* Ensure icons are white */
}

.see-all-button {
  background: none;
  border: 1px solid #fff;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.see-all-button:hover {
  background: rgba(255, 255, 255, 0.1);
}

.amenity-item {
  margin-bottom: 1px; /* Adjust as needed */
}

.amenity-description {
  font-size: 0.875rem;
  color: #848484; /* Adjust color as needed */
  margin-top: 4px; /* Space between name and description */
}