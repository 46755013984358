.ticker {
    background-color: #e6b41e; /* Blue background */
    color: #000;
    padding: 10px 20px;
    text-align: center;
    font-size: 1vw; /* Responsive font size */
    font-weight: bold;
    position: absolute; 
    width: 100%;
    top: 80px;
    z-index: 1000;
    white-space: nowrap; /* Prevents the text from wrapping */
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    height: 60px;
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
}

.ticker span {
    display: inline-block;
    animation: ticker 15s linear infinite; /* Increase the duration for smoother scrolling */
}

.page-content {
    margin-top: 60px; /* Adjust based on the height of the ticker */
}

/* Fine-tune font-size for different screen widths */
@media (max-width: 1200px) {
    .ticker {
        font-size: 2vw;
    }
}

@media (max-width: 992px) {
    .ticker {
        font-size: 2.2vw;
    }
}

@media (max-width: 768px) {
    .ticker {
        font-size: 2.7vw;
    }
}

@media (max-width: 576px) {
    .ticker {
        font-size: 3.5vw;
    }
}
