/* PropertyInfo.css */
.property-info {
    display: flex;
    flex-direction: column;
    gap: 40px; /* This will add space between each child */
}

/* PropertyInfo.css */
.property-info .section {
    padding-bottom: 20px; /* Adjust padding as needed */
}
