/* FeaturesGrid.css */
.features-grid {
  background-color: black;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* Center align the items */
  padding: 20px 0;
  /* Add padding vertically without affecting horizontally */
}

.feature-item {
  width: 30%;
  /* Default width for larger screens */
  margin: 10px;
  padding: 20px;
  text-align: left;
  box-sizing: border-box;
  /* Includes padding and border in the width */
}

.feature-item h2 {
  margin-bottom: 20px;
}

.feature-item p {
  font-size: 1.1rem;
}

/* Responsive adjustments for medium screens */
@media (max-width: 1024px) {
  .feature-item {
    width: 70%;
    /* Use 50% width for medium screens */
  }
}

/* Responsive adjustments for small screens */
@media (max-width: 768px) {
  .feature-item {
    width: 95%;
    /* Increase width to 80% for small screens */
  }
}