.calendar-container-desktop {
  top: 10%;
  z-index: 10;
  padding: 10px;
  border-radius: 10px;
  background-color: #1b1b1b;
  height: 100%;
  position: sticky;
}

.calendar-actions {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}
