* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  /* Consider specifying fallback fonts */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212d3;
  /* Dark background for the whole page */
  color: #e0e0e0;
  overflow-x: hidden;
  padding: 0;
  /* Prevent horizontal overflow */
}

button {
  cursor: pointer;
  outline: none
}

img,
video,
iframe {
  max-width: 100%;
  height: auto;
  /* Maintains the aspect ratio */
}

/* Typography & Link Styles */
body,
button {
  color: #e0e0e0;
  /* Light grey text optimized for dark backgrounds */
}


a:hover,
a:focus {
  text-decoration: underline;
  /* Underline on hover/focus for clarity */
}

/* Utility Classes */
.hidden {
  display: none;
  /* Hides content visually and from screen readers */
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  /* Hides content visually but accessible to screen readers */
}


/* This class will be added to the body when the lightbox is open */
.body-no-scroll {
  overflow: hidden !important;
  position: fixed;
  /* Optionally lock the position */
  width: 100%;
  /* Prevent width reflow */
}