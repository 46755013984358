.faq-container {
  background-color: #000;
  width: 100%;
  display: flex;
  justify-content: center;
}

.faq-section {
  background-color: #000;
  color: #000;
  padding: 40px 20px;
  width: 100%;
  max-width: 1000px;
}

.faq-section .MuiAccordion-root {
  background-color: gold; /* Blue theme */
  color: #000;
  margin-bottom: 16px;
  border: 1px solid #3f6fff; /* Border with a darker blue */
}

.faq-section .MuiAccordionSummary-root {
  background-color: gold; /* Slightly darker blue */
}

.faq-section .MuiAccordionSummary-root .MuiTypography-root {
  font-weight: bold;
}

.faq-section .MuiAccordionDetails-root {
  background-color: #e8c456; /* Even darker blue */
}

.faq-header {
  font-weight: bold;
  margin-bottom: 40px;
  color: #fff;
  text-align: center;
}