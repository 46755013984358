.showcase-section {
  background-color: #000;
  padding: 40px 20px;
}

.showcase-item {
  text-align: center;
  color: #fff;
}

.showcase-image {
  width: 300px;
  height: auto;
  margin-bottom: 10px;
  border-radius: 10px;
  /* border: 2px solid white; */
}

.showcase-button {
  margin-top: 10px;
  font-size: small;
}
