.property-rules-scroller {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping of child elements */
  gap: 20px;
  background: #000;
  border-radius: 10px; /* Optional: Add border radius to the container */
}

.rule {
  display: flex;
  flex-direction: column;
  background-color: #333;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  width: 100%; /* Full width for each rule */
}

.rule h3 {
  margin: 0 0 10px;
}

.rule p {
  margin: 0;
  font-size: 16px;
  line-height: 1.4;
}

.show-all-rules {
  background: none;
  border: none;
  color: #FFF;
  cursor: pointer;
  padding: 5px;
  font-size: 1em;
  text-decoration: underline;
  align-self: flex-end; /* Align the button to the right */
}

@media screen and (max-width: 768px) {
  .property-rules-scroller {
    flex-direction: column;
    align-items: center;
    gap: 10px; /* Smaller gap for smaller screens */
  }

  .rule {
    margin: 0; /* Remove margin if any */
  }
}
