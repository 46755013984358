.reserve-wrapper {
  position: relative;
}

.calendar-container-mobile {
  display: none;
  position: fixed;
  top: 10;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  z-index: 1000;
  padding: 20px;
  background-color: #1b1b1b;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
}

.calendar-container-mobile.show {
  display: flex; /* Ensure flex properties are applied */
  transform: translateY(0);
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendar-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center the content vertically */
}

.calendar-actions {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.reserve-button {
  display: none;
}

@media (max-width: 768px) {
  .reserve-button {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 15px 20px;
    background-color: gold;
    color: #000;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }

  .reserve-button:hover {
    background-color: #e8c456;
  }
}
