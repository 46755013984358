.hero-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: black;
}

.hero-section::after {
  content: "";
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.9));
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 2;
  max-width: 600px;
  padding: 20px;
}

.hero-description {
  display: none; /* Hide by default */

  @media (min-width: 768px) {
    display: block; /* Show on desktop */
  }
}
