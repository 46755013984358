/* PaymentForm.css */

.payment-form-container {
  background-color: #333;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  color: #fff;
}

.payment-form-container h2 {
  margin-bottom: 20px;
  text-align: center;
}

.payment-form {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Adjust gap between form fields */
}

.payment-form input {
  padding: 15px;
  border: none;
  border-radius: 5px;
  background-color: #444;
  color: #fff;
}

.payment-form button {
  padding: 15px;
  border: none;
  border-radius: 5px;
  background-color: #888;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
}

.payment-form button:hover {
  background-color: #aaa; 
}

.payment-form a {
  color: #888;
  text-decoration: underline;
  text-align: center;
  margin-top: 10px;
}

.contact-information {
  margin-top: 20px;
}

.contact-information h3 {
  margin-bottom: 15px;
}

.contact-information input {
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 5px;
  background-color: #444;
  color: #fff;
  margin-bottom: 10px;
}

.rules-agreements {
  margin-top: 20px;
}

.rules-agreements h3 {
  margin-bottom: 10px;
}

.rules-agreements p {
  margin-bottom: 10px;
}

.rules-agreements a {
  color: #888;
  text-decoration: underline;
  cursor: pointer;
}
