.trip-header {
    text-align: left;
    margin-bottom: 20px;
  }
  
  .trip-header h1 {
    font-size: 32px;
    margin: 0;
  }
  
  .trip-header p {
    color: #aaa;
    font-size: 14px;
    margin: 5px 0 0 0;
  }
  