body {
  background-color: #000;
  color: #fff;
  margin: 0;
  font-family: Arial, sans-serif;
}

.trip-summary-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 33vh;
  /* Top third of the screen */
  background-size: cover;
  background-position: center;
  filter: brightness(2);
  /* Dim the image */
  z-index: -1;
  /* margin-top: 65px; */


}

.trip-summary-background {
  background-size: cover;
  background-position: center;
  height: 300px;
  width: 100%;
}

.trip-summary-background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 1));
}

.trip-summary-container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 10vh auto 20px;
  /* Push the container below the background image */
  padding: 20px;
  gap: 20px;
}



.trip-summary-left {
  flex: 3;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* Adjust this value to increase the gap between components */
}

.trip-summary-right {
  flex: 1;
  position: sticky;
  top: 15vh;
  /* Start sticking below the background image */
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* Space between cost breakdown and reviews */
}

.trip-summary-breakdown-box,
.reviews-scroller-box {
  background-color: #333;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  padding: 10px;
  /* disable scrolling */
  overflow: hidden;
}

.trip-summary-content {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* Adjust this value to increase the gap between content items */
}

.trip-summary-header h1 {
  text-align: center;
  margin-bottom: 20px;
}

.trip-summary-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* Adjust this value to increase the gap between details items */
}

.trip-summary-payment {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
}

.payment-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.payment-form input,
.payment-form button {
  padding: 10px;
  border: none;
  border-radius: 5px;
}

.payment-form input {
  background-color: #444;
  color: #fff;
}

.payment-form button {
  background-color: #888;
  color: #fff;
}

.contact-information input {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #444;
  color: #fff;
  margin-bottom: 10px;
}

.contact-information button {
  width: 100%;
  padding: 15px;
  background-color: #888;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
}

.contact-information a {
  color: #888;
  text-decoration: underline;
  margin-top: 10px;
  display: block;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #444;
  margin: 20px 0;
}

/* Specific to TripCheckoutPage */
.trip-checkout-reviews-scroller .reviews-scroller {
  background: #333;
}

.fixed-pay-button {
  display: none;
}

@media (max-width: 768px) {
  .fixed-pay-button {
    display: block;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 40px);
    /* Adjust width to leave some space on the sides */
    color: #000;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    z-index: 1000;
  }


  .fixed-pay-button .mobile-calendar-button {
    width: 100%;
    padding: 20px 0;
    border-radius: 10px;
    /* background-color: #fff; */
    color: #000;
    font-weight: bold;
    text-transform: none;
  }

  .fixed-pay-button .mobile-calendar-button:hover {
    background-color: #e9bc34;
  }
}

@media screen and (min-width: 769px) {
  .fixed-pay-button {
    display: block;
    position: sticky;
    bottom: 0;
    width: 100%;
    color: #000;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    padding: 15px 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .fixed-pay-button .mobile-calendar-button {
    width: 80%;
    padding: 20px 0;
    border-radius: 10px;
    font-weight: bold;
    text-transform: none;
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}