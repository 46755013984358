.trip-details {
    margin-bottom: 20px;
    /* disable scrolling */
    overflow: hidden;
  }
  
  .trip-detail-item {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .calendar-container {
    max-height: 0;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
    opacity: 0;
  }
  
  .calendar-container.show {
    max-height: 500px; /* Adjust the height as needed */
    opacity: 1;
    transition: max-height 0.5s ease-in, opacity 0.5s ease-in;
  }
  
  .calendar-container .PropertyCalendarDesktop {
    transform: scale(0.9); /* Adjust the scale as needed */
  }
  
  .trip-detail-item button {
    background: none;
    border: 1px solid #fff;
    color: #fff;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    margin-top: 5px; /* Add margin for better spacing */
  }
  
  .calendar-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px; /* Add margin to separate buttons from calendar */
  }
  
  .trip-detail-item input[type="number"] {
    padding: 5px;
    margin-right: 10px;
    width: 50px; /* Adjust width as needed */
  }
  