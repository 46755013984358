.hero-section {
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.7) 0%, 
      rgba(0, 0, 0, 0) 20%,
      rgba(0, 0, 0, 0) 20%, 
      rgba(0, 0, 0, 0.7) 100%
    ),
    url('https://www.datocms-assets.com/53643/1698329540-hudson_11zon.jpg?auto=format') center/cover no-repeat;
  height: 75vh;
  display: flex;
  justify-content: center;
  color: white;
  text-align: center;
}

.hero-content {
  max-width: 600px;
  padding: 20px;
  /* Increase space between each heading */
  position: relative; /* Ensure content is above the gradient */
  z-index: 2;
}
