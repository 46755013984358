/* RegisterModal.css */
.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 800px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
  outline: none;
}

.modal-header {
  text-align: center;
  margin-bottom: 20px;
}

.modal-header h2 {
  margin: 0;
  color: #000;
}

.modal-body {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.modal-body .MuiTextField-root {
  width: 100%;
  padding-right: 10px;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-right: 10px;
  gap: 10px;
}

.modal-footer label {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #000;
}
