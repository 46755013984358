.testimonials-section {
  background-color: #000;
  color: #000;
  padding: 40px 20px;
  text-align: center;
}

.testimonials-header {
  font-weight: bold;
  margin-bottom: 40px;
  color: #fff;
}

.testimonial-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1000px;
  margin: 0 auto;
}

.testimonial-box {
  background-color: #e6b41e; /* Blue color for testimonial boxes */
  border: 2px solid white;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  flex: 0 1 calc(50% - 40px); /* Two testimonials per row */
  box-sizing: border-box;
  text-align: center;
}

.testimonial-box p {
  margin: 0;
  font-size: medium;
  position: relative;
}

.testimonial-box p::before,
.testimonial-box p::after {
  content: '"';
  color: #000;
  font-size: 2rem;
  position: absolute;
}

.testimonial-box p::before {
  left: -1rem;
  top: -1rem;
}

.testimonial-box p::after {
  right: -1rem;
  bottom: -1rem;
}

@media (max-width: 768px) {
  .testimonial-box {
    flex: 0 1 calc(100% - 40px); /* One testimonial per row on small screens */
  }
}
