/* Filters.css */
.filters {
  display: flex;
  justify-content: flex-start; /* Align items to the start for small screens */
  padding: 10px 0;
  padding-left: 20px;
  background-color: #252424; /* Dark background suitable for dark theme */
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Keep all buttons in a single line */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS devices */
  scrollbar-width: none; /* Hide scrollbar for browsers that support this property */
}

.filters::-webkit-scrollbar {
  display: none; /* Hide scrollbar for webkit browsers */
}

.filters button {
  background: none;
  border: none;
  padding: 10px 15px;
  margin-right: 5px; /* Adjusted for consistency */
  color: #ccc; /* Light grey text for visibility */
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out; /* Smooth transition */
}

.filters button:last-child {
  margin-right: 0; /* Remove margin for the last button to prevent extra spacing */
}

.filters .button-active {
  background-color: #565656; /* Dark grey for active/hover state */
}

/* Media query for larger screens */
@media (min-width: 768px) {
  .filters {
    justify-content: center; /* Center the buttons on larger screens */
    overflow-x: visible; /* Disable horizontal scrolling */
    white-space: normal; /* Allow normal text wrapping */
    padding-left: 10px;
  }
}


@media (hover: hover) {
  .filters button:hover {
    background-color: #565656; /* Ensure hover styles are applied only when hovering is possible */
  }
}
