.title-section {
    color: white;
    background-color: black;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    /* trans */
    background-color: rgba(0, 0, 0, 0.5);
}

.location {
    font-size: 1em;
    letter-spacing: 2px;
    opacity: 0.7;
}

.title-section h1 {
    font-size: 3.5em;
    margin: 0.5em 0;
}

.details {
    font-size: 1em;
    display: flex;
    justify-content: center;
    gap: 20px;
}

@media screen and (max-width: 768px) {
    .title-section {
        color: white;
        background-color: black;
        padding-top: 20px;
        text-align: center;
        /* trans */
        background-color: rgba(0, 0, 0, 0.5);
    }

    .location {
        font-size: 0.75em;
        letter-spacing: 2px;
        opacity: 0.7;
    }

    .title-section h1 {
        font-size: 1.5em;
        margin: 0.5em 0;
    }

    .details {
        font-size: 0.75em;
        display: flex;
        justify-content: center;
        gap: 20px;
    }

}