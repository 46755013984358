/* Main grid container styling */
.grid-container {
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* justify-content: center; */
    /* padding: 20px; */
    gap: 40px; 
    background-color: #00000043; 
}

.grid-property-section {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 5px;
    padding-left: 5%;
    padding-right: 5%;
    
    /* transition: transform 0.3s ease-in-out; */
}

.loading-text {
    font-size: 20px;
    font-weight: 600;
    margin: 20px;
    /* center */
    display: flex;
    justify-content: center;
}

@media (max-width: 768px) {
    .grid-property-section {
        padding: 0; /* Remove padding on smaller screens */
    }
}