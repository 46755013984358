.property-signup {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #333;
  border-radius: 10px;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2);
  color: #fff;
  margin-top: 100px;
}

.property-signup h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #fff;
}