/* PropertyPage.css */
body {
    background-color: #000;
}

.property-page-container {
    /* max-width: 2000px; */
    margin: auto;
    color: #fff;
    background-color: #000;
}

.property-page-container {
    /* max-width: 2000px; */
    margin: auto;
    color: #fff;
    background-color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.property-cover-image {
    width: 100%;
    height: 30vw;
    background-size: cover;
    background-position: center;
    position: relative;

    /* Fade given image to black in the bottom half  */
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        /* background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%); */
    }

    /* background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%); */
}

.property-content-container {
    max-width: 1200px;
    /* Limiting max width to keep content contained */
    width: 100%;
    /* This ensures it takes up the space it can within 1200px */
    padding: 20px;
}

.property-info,
.property-calendar {
    flex: 1;
    padding: 20px;

    /* Padding for content inside columns */
}

@media (max-width: 768px) {
    .property-content-container {
        flex-direction: column;
    }

    .property-calendar {
        display: none;
    }

    .reserve-button {
        display: block;
        /* Show reserve button on mobile */
    }

    .property-cover-image {
        height: 80vw;
        /* Adjusted for a more compact look */
    }

}


.title-section {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    align-self: center;
    text-align: left;
    padding-left: 25%;
    padding-right: 25%;
}

.date-picker {
    background: #333;
    padding: 15px;
    border-radius: 8px;
}

.cover-image {
    width: 100%;
    height: 300px;
    /* Adjusted for a more compact look */
    background-size: cover;
    background-position: center;
}

.info-columns {
    display: flex;
    justify-content: space-between;
}

.info-column {
    max-width: 800px;
    border-radius: 8px;
    margin: 10px;
}

.info {
    flex: 3;
}

.calendar {
    flex: 1;
    margin-left: 20px;
}

@media (max-width: 768px) {
    .info-columns {
        flex-direction: column;
    }

    .calendar {
        display: none;
        /* Hide calendar on smaller screens */
    }

    /* .reserve-button {
        position: fixed;
        bottom: 0;
        width: 100%;
        text-align: center;
        padding: 20px;
        background-color: #fff;
        color: black;
        font-size: 18px;
        border: none;
        cursor: pointer;
    } */

    .title-section {
        padding-left: 2.5%;
        padding-right: 2.5%;
    }
}


.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}