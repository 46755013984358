/* TermsModal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #333;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* make H2 white */
.modal-header h2 {
  color: #fff;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5em;
  color: #fff;
  cursor: pointer;
}

.modal-body {
  margin-top: 10px;
  max-height: 60vh;
  overflow-y: auto;
}
