.landing-page {
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  /* min-height: 100vh; */
  background-color: #121212;
  /* color: #ffffff; */
  /* text-align: center; */
  /* padding: 20px; */
  /* margin-bottom: 50px; */
  /* Make the div start right at the top */
  position: absolute;
  top: -10px;

}

.landing-page .logo {
  /* max-width: 200px; */
  /* margin-bottom: 20px; */
}

.landing-page h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.landing-page p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.landing-page .MuiButton-root {
  font-size: 1.25rem;
  padding: 0.75rem 2rem;
}