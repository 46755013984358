.hero {
  background: url('https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80') no-repeat center center/cover;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: white;  
}

.hero h1 {
  font-size: 48px; /* Consider using responsive units like vw for smaller screens */
  margin-bottom: 20px;
}

.hero input {
  width: 50%; /* Responsive width adjustments for smaller screens */
  max-width: 500px; /* Ensures input isn't too wide on larger screens */
  padding: 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent for better visibility */
  color: #333; /* Dark text color for contrast */
}

@media (max-width: 600px) {
  .hero h1 {
    font-size: 6vw; /* Smaller font size on mobile devices */
  }

  .hero input {
    width: 70%; /* Wider input on smaller screens */
  }
}
