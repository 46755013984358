/* WhyPartnerSection.css */
.why-partner-section {
  padding: 40px 20px;
  background-color: #000;
  color: white;
  text-align: center;
  padding-top: 60px;
  /* margin-top: 60px; */
}

.feature-box-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.feature-box {
  text-align: center;
  padding: 20px;
  border: 1px solid white;
  /* White border around each box */
  border-radius: 10px;
  /* Rounded corners */
  margin: 10px;
  /* Margin for spacing between boxes */
  flex: 1 1 200px;
  /* Flex properties to ensure they are evenly spaced */
  max-width: calc(25% - 20px);
  /* Ensure four boxes fit on one line */
  box-sizing: border-box;
  /* Ensure padding and border are included in the width */
}

.feature-icon {
  color: #e6b41e;
  /* Icon color */
  margin-bottom: 10px;
}

@media (max-width: 960px) {
  .feature-box {
    max-width: calc(50% - 20px);
    /* Two boxes per row on smaller screens */
  }
}

@media (max-width: 600px) {
  .feature-box {
    max-width: 100%;
    /* One box per row on very small screens */
  }
}