.footer {
    display: flex;
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
    background-color: #000;
    color: #fff;

}

.footer-section h4 {
    margin-bottom: 20px;
}

.footer-section {
    display: flex;
    flex-direction: column;
}

.footer-link {
    margin-bottom: 10px;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 5px;
    /* Increase the bottom margin to create more space */
}

.footer-section ul li a {
    color: #ccc;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-section ul li a:hover {
    color: #fff;
}

.footer-social {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.social-media a {
    margin-right: 10px;
    font-size: 24px;
    
    /* Adjust size accordingly */
}

.social-media a i {
    color: #ccc;
}

.social-media a i:hover {
    color: #fff;
}

@media (min-width: 768px) {

    /* Adjustments for larger screens */
    .footer-section ul li {
        margin-bottom: 5px;
        /* Larger spacing for desktop screens */
    }
}

.social-media {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.social-media h4 {
    margin-bottom: 10px;
}

.social-media a {
    margin: 0 10px;
    font-size: 24px;
    color: #ccc;
    transition: color 0.3s ease;
}

.social-media a:hover {
    color: #fff;
}

@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        align-items: left;
    }

    .footer-section {
        align-items: left;
        margin-bottom: 20px;
    }

    .footer-social {
        justify-content: center;
        width: 100%;
    }
}

/* Additional styles for copyright and website text */
.footer-bottom {
    text-align: center;
    width: 100%;
    padding-top: 20px;
    border-top: 1px solid #666;
    margin-top: 20px;
    /* Light grey border for a subtle separation */
}