.property-card {
  width: 300px;
  margin: 15px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  color: #ffffff;
  font-family: Arial, sans-serif;
  cursor: pointer;
}

@media (max-width: 768px) {
  .property-card {
    width: 100%;
    margin: 0;
    margin-top: 10px;
    border-radius: 0;
  }

  .property-card img {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
  }
}

/* .property-card:hover {
  transform: translateY(-5px);
} */

.property-card-info {
  background-color: rgba(0, 0, 0, 0.609);
  padding: 10px;
}

/* .property-card-info:hover {
  background-color: rgba(0, 0, 0, 0.9);
} */

.property-title {
  font-size: 20px;
  font-weight: bold;
}

.property-location {
  font-size: 15px;
  opacity: 0.7;
  margin-top: 5px;
  margin-bottom: 5px;
}

.property-availability {
  font-size: 14px;
  margin-top: 5px;
}

.property-icon {
  display: inline-flex;
  align-items: center;
  margin-top: 5px;
  margin-right: 5px;
  color: white;
}

.property-icon svg {
  margin-right: 3px;
  width: 14px;
  height: 14px;
}

.property-availability {
  font-size: 14px;
  margin-top: 5px;
  color: #fff;
}

.availability-label {
  font-weight: normal;
}

.availability-date {
  font-weight: bold;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.287);
  color: white;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
  border: 0;
}

.arrow.next {
  right: 10px;
}

.arrow.prev {
  left: 10px;
}

/* .property-card:hover .arrow {
  display: flex;
  opacity: 10;
  transition: opacity 1s;
} */

.property-card-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

/* Custom styling for slick dots to overlay on the image */
.slick-dots {
  position: absolute;
  bottom: 10px;
  /* Adjust as needed for positioning from the bottom */
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 0;
  margin: 0;
  list-style-type: none;
  text-align: center;
}

.slick-dots li {
  display: inline-block;
  margin-right: 5px;
  cursor: pointer;
}

.slick-dots li.slick-active button:before {
  opacity: 0.85;
  /* Active dot opacity */
  color: #fff;
  /* Color of the active dot */
}

.slick-dots li button:before {
  font-size: px;
  /* Size of the dots */
  color: rgba(255, 255, 255, 0.5);
  /* Color of the dots */
  opacity: 1;
}

.slick-slider {
  position: relative;
}

.slick-slide img {
  display: block;
  width: 100%;
  max-height: 200px;
}

/* Adjust the z-index if the dots do not appear over the slider */
.slick-dots {
  z-index: 1;
}

@media (hover: hover) {
  .property-card:hover {
    transform: translateY(-5px);
    background-color: #333;

  }

  .property-card:hover .arrow {
    display: flex;
    opacity: 1;
    transition: opacity 1s;
  }
}