/* ManageEnquiries.css */

.manage-enquiries-container {
    padding: 20px;
}

.manage-enquiries-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    font-weight: bold;
    padding-bottom: 20px;
}

.enquiry-card-title {
    font-size: 1.25rem;
    margin-bottom: 8px;
    color: #fff;
    font-weight: bold; /* Make the email bold */
}

.enquiry-card-info {
    font-size: 0.875rem;
    color: #fff;
    margin-bottom: 8px;
}

.enquiry-card-info span {
    font-weight: bold; /* Make the title bold */
}

.enquiry-card-actions {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
}

.enquiry-card-actions button {
    width: 48%;
}


.enquiry-card-actions .delete-button {
    background-color: #f44336;
}

.enquiry-card-actions .responded-button:disabled {
    background-color: #9e9e9e;
}

@media (max-width: 600px) {
    .enquiry-card-actions {
        flex-direction: column;
    }

    .enquiry-card-actions button {
        width: 100%;
        margin-bottom: 8px;
    }

    .enquiry-card-actions button:last-child {
        margin-bottom: 0;
    }
}
