.step-section {
  background-color: #000;
  color: #000;
  padding: 40px 20px;
  text-align: center;
}

.step-header {
  font-weight: bold;
  margin-bottom: 40px;
  color: #fff;
}

.step-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1000px;
  margin: 0 auto;
}

.step-box {
  border: 2px solid white;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  flex: 0 1 calc(25% - 40px);
  box-sizing: border-box;
  text-align: center;
  background-color: #e8c456; /* Blue color for step boxes */
}

.step-box:nth-of-type(1) {
  background-color: #e6b41e;
}

.step-box:nth-of-type(2) {
  background-color: #e8be43;
}

.step-box:nth-of-type(3) {
  background-color: #e2c468;
}

.step-box:nth-of-type(4) {
  background-color: #dfcc93;
}

.step-box h3 {
  margin-bottom: 10px;
}

.step-box p {
  margin-top: 0;
  font-size: medium;
}

@media (max-width: 768px) {
  .step-box {
    flex: 0 1 calc(50% - 40px);
  }
}

@media (max-width: 480px) {
  .step-box {
    flex: 0 1 calc(100% - 40px);
  }
}
